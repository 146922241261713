@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'styles/_main-table';
@import 'styles/_main-table-filters';
@import 'styles/_classic-form-items';
@import 'styles/_toastr';
@import 'styles/_buttons';
@import 'styles/_responsive';
@import 'mobile/_mobile';

// ANGULAR MATERIAL
@import 'styles/angular-material/_stepper';
@import 'styles/angular-material/_mat-checkbox';
@import 'styles/angular-material/_mat-inputs';
@import 'styles/angular-material/virtual-scroll';

// 3RD PARTY LIBRARIES
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/scale.css';

//// ANGULAR MATERIAL THEME
$my-primary: mat.m2-define-palette(mat.$m2-teal-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-yellow-palette, 800);
$my-typography: mat.m2-define-typography-config(
  $font-family: ' "Nunito", sans-serif',
);
$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    typography: $my-typography,
  )
);
@include mat.typography-hierarchy($my-typography);
@include mat.all-component-themes($my-theme);
@include mat.core();

// TAILWIND
@tailwind base;
@tailwind components;
@tailwind utilities;

::-ms-reveal {
  display: none;
}

$orange: #ffa600;
$blue: #82d2e5;
$green: #c1d72e;
:root {
  --ui-primary: #115e68;
  --ui-success: #ffb55e;
  --ui-error: #e3594a;
  --ui-highlight: #9399e2;
  --dark: #1d1e25;
  --black-100: #343748;
  --gray-dark: #162a2d;
  --gray-light: #e5e5e5;
  --green-light: #38c0a1;
  --status-text: #abc5c7;
  --white: #fff;
  --gray-50: #f8f8f8;
  --gray-100: #737788;
  --gray-125: #9b9faa;
  --gray-150: #9e9e9e;
  --gray-200: #fafbfa;
  --gray-250: #5c5f6d;
  --gray-300: #f1f1f2;
  --gray-350: #cacbd2;
  --gray-400: #ededed;
  --gray-450: #777777;
  --gray-500: #a0bfc3;
  --gray-550: #8f92a0;
  --gray-600: #333333;
  --gray-650: #e5e5ea;
  --gray-700: #6e7c83;
  --gray-750: #c0c0c0;
  --gray-800: #4d4d4d;
  --gray-850: #f5f5f5;
  --gray-900: #f6f6f6;

  --green-50: #d7f2ec;
  --green-100: #009e80;
  --green-150: #effaf8;
  --green-200: #2d9a81;
  --green-250: #effaf7;
  --green-300: #548d92;
  --green-350: #329e86;
  --green-400: #b3e7db;
  --green-450: #44e5c0;
  --green-500: #E5F5F2;

  --red-50: #fe5a5a;
  --red-100: #ff2424;
  --red-150: #cb4848;
  --red-200: #BE391B;
  --red-250: #B23F3F;
  --red-300: #FFDEDE;

  --yellow-100: #ffdc24;
  --yellow-150: #b09507;
  --yellow-200: #e5c100;
  --yellow-250: #ff9f47;
  --yellow-300: #cc794e;
  --yellow-350: #857108;
  --yellow-400: #FFEAD1;

  --blue-100: #6f7dff;
  --blue-150: #5964cc;
  --blue-200: #243aff;
}

.appearance-textfield {
  -webkit-appearance: textfield !important;
  appearance: textfield !important;
}

.no-elevation {
  @include mat.elevation(0);
}

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  @apply font-nunito text-ui-dark;
}

body .main-menu-item-active {
  mat-label {
    @apply font-bold;
  }
}

h1 {
  @apply text-4xl;
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-2xl;
}

h4 {
  @apply text-xl;
}

h5 {
  @apply text-base;
}

h6 {
  @apply text-sm;
}

h7 {
  @apply text-xs;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  @apply font-poppins;
}

.mobile-page-main-title {
  @apply m-0 font-poppins font-semibold text-xl text-ui-dark;
}

.app-block-shadow {
  box-shadow: 0 6px 22px 0 rgba(222, 223, 226, 0.51);
}

mat-card.styled-form,
.styled-form {
  max-width: 498px;
  background: transparent;
  @apply px-6;

  .mdc-text-field--filled {
    background: var(--white);
  }

  mat-error {
    margin-top: -24px;
    padding-left: 50px;
    @apply text-xs text-ui-error;
  }

  mat-form-field:first-of-type {
    .mat-mdc-text-field-wrapper {
      border-radius: 8px 8px 0 0;
    }
  }

  mat-form-field {
    .mat-mdc-text-field-wrapper {
      border-radius: 0;
    }
  }

  .mdc-text-field--filled .mdc-line-ripple::after {
    border-bottom-color: var(--green-light);
  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
    border-bottom-color: var(--ui-error);
  }

  mat-form-field:last-of-type {
    .mat-mdc-text-field-wrapper {
      border-radius: 0 0 8px 8px;
    }
  }

  mat-label {
    @apply text-sm;
  }

  .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-115%) scale(1);
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    @apply text-ui-gray-100;
  }

  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    @apply text-ui-gray-100;
  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
    @apply text-ui-error;
  }

  .mat-mdc-text-field-wrapper {
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }

  &.single-control-form {
    mat-form-field {
      .mat-mdc-text-field-wrapper {
        border-radius: 8px;
      }
    }
  }
}

// Sidebar menu
.sidebar-menu {
  @apply overflow-visible;
  .mdc-list-item::before{
    @apply mx-2 rounded;
  }

  @include mat.list-overrides((
    list-item-hover-state-layer-color: white,
    list-item-hover-state-layer-opacity: 0.1,
  ));

  .mdc-list-item.main-menu-item-active::before {
    @apply bg-white opacity-30 rounded w-auto p-0 #{!important};
  }
}

mat-card.mat-mdc-card {
  @apply shadow-none;
}

.mat-mdc-card.card {
  @apply p-6 rounded-lg shadow-card;
}

.card-separator {
  @apply py-3;
}

.card-title {
  @apply text-ui-dark text-base font-semibold font-poppins leading-5 tracking-normal #{!important};
}

.card-title-small {
  @apply text-ui-dark text-sm font-semibold font-poppins leading-cart-title tracking-normal #{!important};
}

.input-main-form {
  @apply flex items-start classic-form-item-wrapper;

  div + .input-main {
    width: 246px;
  }
}

.input-main {
  @apply w-full;

  &:hover {
    .mat-mdc-text-field-wrapper {
      @apply bg-ui-gray-50;
    }
  }

  .mat-mdc-form-field-focus-overlay {
    @apply bg-transparent;
  }

  .mdc-line-ripple {
    &::before,
    &::after {
      @apply hidden;
    }
  }

  .mat-mdc-text-field-wrapper {
    @apply rounded-md border border-ui-gray-light bg-ui-gray-50 transition-colors duration-300;

    &.mdc-text-field--invalid {
      @apply border-ui-error;

      .mat-mdc-form-field-icon-suffix {
        .mat-icon {
          @apply text-ui-error;
        }
      }
    }
  }

  .mat-mdc-form-field-icon-suffix {
    @apply p-0;

    .mat-icon {
      @apply text-ui-green-100 p-1.5;
    }
  }

  & + button[type='submit'] {
    @apply -ml-14 mt-2 relative -z-[1];
    &.is-active,
    &:focus {
      @apply z-[1];
    }

    .mat-icon {
      @apply text-ui-green-100 box-content p-1.5 align-top;
    }
  }

  &.mat-focused {
    .mode-edit-icon {
      @apply hidden;
    }

    .mat-mdc-text-field-wrapper {
      @apply bg-white;
    }

    .mat-mdc-text-field-wrapper {
      @apply border-ui-green-light;
    }
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
    @apply text-sm text-ui-dark h-10 focus:outline-none;
  }

  .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
    @apply text-ui-gray-100;
  }

  .mat-mdc-form-field-infix {
    @apply text-sm min-h-0;
  }

  .mat-mdc-form-field-flex {
    @apply h-[38px];
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    @apply py-[7px] max-w-[calc(100%-56px)];
  }

  .mat-mdc-form-field-error-wrapper {
    @apply -mt-1;
  }

  .mat-mdc-form-field-error {
    @apply text-ui-error text-xs;
  }

  &.hide-error-message .mat-mdc-form-field-subscript-wrapper {
    @apply hidden;
  }

  &.bg-opaque {
    .mat-mdc-text-field-wrapper {
      @apply bg-transparent;
    }
  }

  &.bg-light {
    .mat-mdc-text-field-wrapper {
      background-color: var(--white);
    }
  }
}

.input-main-label {
  @apply text-sm font-semibold text-ui-gray-100 w-52 mr-6 mt-2.5 leading-cart-title;
}

.main-toggle {
  &.is-header-toggle {
    @apply w-full;

    .mdc-form-field {
      @apply flex;
      label {
        @apply flex justify-between items-center ml-0 w-full py-4;
      }
    }
  }

  .mdc-form-field {
    label,
    span {
      @apply text-sm;
    }

    label {
      @apply font-normal text-ui-dark;
    }

    span {
      @apply mr-9 text-ui-gray-100 font-semibold;

      &.main-placeholder {
        @apply w-52 mr-6 inline-flex;
      }
    }

    .mdc-switch .mdc-switch__track {
      @apply absolute;
      &::before {
        background-color: rgba(158, 158, 158, 0.5);
      }

      &::after {
        @apply opacity-20 bg-ui-green-light;
      }
    }

    .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::before,
    .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::before {
      background-color: rgba(158, 158, 158, 0.5);
    }

    .mdc-switch__handle::before {
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }

    .mdc-switch.mdc-switch--unselected:enabled:active .mdc-switch__handle::after,
    .mdc-switch.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after,
    .mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after,
    .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
      @apply opacity-20 bg-ui-gray-50;
    }

    .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus) .mdc-switch__ripple::after,
    .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__ripple::after,
    .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__handle::after,
    .mdc-switch:enabled:active .mdc-switch__track::after,
    .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after,
    .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after,
    .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after,
    .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
    .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after {
      @apply bg-ui-green-light;
    }

    .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__ripple::after {
      @apply opacity-20;
    }

    .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus) .mdc-switch__ripple::after {
      @apply opacity-10;
    }

    .mdc-switch__icons {
      @apply hidden;
    }

    .mdc-switch--disabled {
      &.mdc-switch--unselected {
        .mdc-switch__track {
          @apply opacity-100;

          &::before {
            @apply bg-ui-gray-400;
          }
        }

        .mdc-switch__handle {
          &::before {
            @apply bg-ui-gray-50;
          }

          &::after {
            @apply opacity-0;
          }
        }

        .mdc-switch__shadow {
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.2);
        }
      }

      &.mdc-switch--selected {
        .mdc-switch__track {
          @apply opacity-100;

          &::after {
            @apply bg-ui-green-400;
          }
        }

        .mdc-switch__handle {
          &::before {
            @apply bg-ui-green-400;
          }

          &::after {
            @apply opacity-0;
          }
        }

        .mdc-switch__shadow {
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}

// Dialog styles start

.app-dialog {
  width: 704px;

  mat-dialog-container {
    .mat-mdc-dialog-surface {
      @apply rounded-xl;
    }

    .dialog-title {
      @apply border-b font-semibold text-2xl flex flex-row justify-between items-center py-6 px-[34px] font-poppins leading-6 text-ui-gray-600;

      button {
        @apply flex items-center;

        i {
          @apply text-3xl;
        }
      }
    }

    .dialog-content {
      @apply py-6 px-[34px];
    }

    .dialog-actions {
      @apply pb-6 pt-4 px-[34px];
    }
  }
}

.backdrop-modal-panel {
  background: rgba(51, 51, 51, 0.5);
}

// Material tabs start
.mat-mdc-tab-list {
  @apply border-b-ui-gray-light border-b mb-6 overflow-hidden;

  .mat-mdc-tab.mdc-tab {
    @apply h-[64px] px-2;

    &:not(:first-child) {
      @apply ml-6;
    }
  }

  .mat-mdc-tab-labels {
    .mdc-tab__content .mdc-tab__text-label {
      @apply font-poppins text-ui-dark text-sm tracking-normal font-normal;
    }

    .mdc-tab--active {
      .mdc-tab__text-label {
        @apply font-semibold;
      }
    }

    .mdc-tab-indicator {
      > span {
        @apply absolute left-0 right-0 w-auto h-[6px] bg-ui-green-light rounded-[100px] bottom-[-3px] border-transparent;
      }
    }
  }
}

.site-device-status {
  @apply font-nunito font-semibold text-xs inline-flex items-center ml-3;

  &:first-child {
    @apply ml-0;
  }
}

.status-indicator {
  @apply inline-block w-1.5 h-1.5 rounded-full mr-1;
}

.mat-expansion-panel-body {
  @apply overflow-hidden p-0 rounded-lg #{!important};
}

#av-services-app {
  .mat-button-toggle .mat-button-toggle-label-content,
  .mat-mdc-menu-item-text,
  .mat-mdc-button,
  .mat-mdc-dialog-container .mdc-dialog__title,
  .mat-mdc-dialog-container .mdc-dialog__content,
  .mdc-text-field--filled .mdc-floating-label,
  .mat-mdc-option,
  .mat-mdc-form-field,
  .mdc-form-field {
    @apply tracking-normal;
  }

  .export-data-overlay {
    @apply w-[250px];

    .mat-mdc-menu-item {
      @apply min-h-[40px] bg-transparent;

      .mat-mdc-menu-item-text,
      .mdc-list-item__primary-text {
        @apply text-sm text-ui-dark transition-colors;
      }

      &:hover:not([disabled]) {
        @apply bg-ui-green-150;

        .mat-mdc-menu-item-text,
        .mdc-list-item__primary-text {
          @apply text-ui-green-light;
        }
      }
    }
  }

  .gm-ui-hover-effect {
    @apply hidden #{!important};
  }

  .gm-style-iw {
    @apply pl-4 pt-4;
  }

  .mat-bottom-sheet-container {
    @apply rounded-t-[30px] px-0 pb-0;

    .sheet-title {
      @apply m-0 font-poppins font-semibold text-2xl text-ui-dark;
    }

    .date-range-picker {
      .mat-mdc-form-field-subscript-wrapper {
        @apply hidden;
      }
    }
  }

  [class*='__ripple'] {
    display: none !important;
  }

  highcharts-chart:not(.is-mobile) {
    .highcharts-reset-zoom {
      transform: translate(calc(100% - 95px), 8px);
    }
  }

  highcharts-chart {
    @apply bg-white;
  }

  .is-hidden-tab {
    @apply h-0 invisible overflow-hidden block;
  }

  .cdk-overlay-container {
    z-index: 9999;
  }

  .mat-mdc-select-panel {
    @apply app-block-shadow;
  }

  &.is-mobile .main-table,
  &:not(.is-mobile) {
    .action-icon {
      @apply text-ui-gray-100 text-3xl cursor-pointer inline-flex items-center justify-center mr-1.5;
      &:hover {
        color: var(--dark);
      }

      &:last-child {
        @apply mr-0;
      }
    }
  }

  .gm-style .gm-style-iw-c,
  .tippy-box {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  }
}

.highcharts-credits {
  @apply hidden;
}

.highcharts-title {
  @apply capitalize font-poppins font-semibold text-base -translate-x-2.5 #{!important};
}

.highcharts-line-series text {
  @apply capitalize;
}

.highcharts-no-legend {
  .highcharts-legend {
    @apply hidden;
  }
}

.highcharts-classic-axes {
  .highcharts-axis-labels text {
    @apply font-nunito text-xs text-ui-gray-450 fill-ui-gray-450 #{!important};
  }

  .highcharts-axis text {
    @apply font-nunito text-ui-gray-dark font-semibold text-sm #{!important};
  }

  .highcharts-a11y-proxy-group {
    @apply hidden;
  }
}

.highcharts-small-title {
  .highcharts-title {
    @apply text-sm #{!important};
  }
}

.manufacturer-highchart .highcharts-container {
  @apply h-full;

  .highcharts-series-group {
    @apply pointer-events-none;
  }
}

.tippy-content {
  @apply p-0;
}

.incidents-amount-indicator {
  @apply flex items-center justify-center min-w-5 h-5 px-1 rounded-full bg-ui-red-100 text-white text-xs font-poppins text-center font-normal;
}

.custom-text-ellipsis {
  @apply overflow-hidden whitespace-nowrap overflow-ellipsis;
  &::after {
    content: '';
    display: block;
  }
}

.multiline-tooltip {
  white-space: pre-line;
}

::ng-deep .mat-tooltip {
  z-index: 9999;
}

.tippy-variation-popper {
  z-index: 1001 !important;
}

.mat-mdc-tab .mdc-tab__ripple::before,
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  @apply rounded-t-lg;
}

.incident-status-chart svg .highcharts-label text {
  @apply text-[10px] text-ui-dark font-nunito font-semibold #{!important};
}

.bottom-sheet-accordion {
  .mat-expansion-panel {
    @apply shadow-none rounded-none #{!important};
  }

  .mat-expansion-panel-spacing {
    @apply m-0;
  }

  .mat-expansion-panel-header {
    @apply px-6 hover:bg-transparent h-[54px] border-b border-ui-gray-light flex-row-reverse #{!important};
  }

  .mat-expansion-panel-header-title {
    @apply text-ui-dark font-poppins font-medium text-base mr-0 ml-3 flex justify-between items-center;
  }

  .mat-expansion-panel-content {
    @apply bg-ui-gray-200 border-b border-ui-gray-light px-6;
  }

  .mat-expansion-panel-body {
    @apply my-3;
  }

  .mat-expansion-indicator {
    &::after {
      @apply border-ui-dark;
    }
  }
}

.mobile-single-filter-select {
  li {
    @apply text-ui-dark font-nunito text-base flex items-center justify-between py-3 cursor-pointer;

    &.checked {
      @apply font-bold;

      i {
        @apply visible;
      }
    }

    i {
      @apply invisible;
    }
  }
}

.mobile-filter-clear-btn {
  @apply text-base font-nunito underline font-semibold text-ui-dark;
}

.mobile-filter-clear-all-btn {
  @apply text-base font-nunito underline font-semibold text-ui-green-light;
}

.mobile-multiselect-filter-inicator {
  @apply ml-2 h-5 rounded-full bg-ui-green-light text-white font-nunito font-semibold text-xs px-1;
}

.mobile-single-select-title {
  @apply whitespace-nowrap w-[75%] text-ellipsis overflow-hidden;
}

.mat-expansion-panel-header[aria-disabled='true'] {
  .mobile-single-select-title {
    @apply text-ui-gray-100;
  }
}

.edit-attachment-popover {
  @apply bg-ui-gray-800 h-9 px-3 text-white text-xs flex items-center;
}

.add-datapoint-accordion {
  .mat-expansion-panel {
    @apply shadow-none bg-ui-gray-50 #{!important};
  }

  .mat-expansion-panel-header {
    @apply px-4 h-14 bg-transparent #{!important};
  }

  .mat-expansion-panel-header-title {
    @apply font-poppins font-semibold text-sm text-ui-dark;
  }

  .mat-expansion-indicator::after {
    @apply border-ui-dark;
  }

  .mat-expansion-panel-body {
    @apply p-4 bg-transparent #{!important};
  }
}

app-spaceplan {
  @apply relative block;
}

img {
  -webkit-user-drag: none;
}

::selection {
  background-color: #e4edfd;
}

.forbid-children-events > * {
  pointer-events: none;
}
