#av-services-app {
  @media screen and (max-width: 1100px) {
    app-dashboard-location-header {
      & > div {
        @apply block #{!important};
        & > div:last-child {
          //@apply pt-4 #{!important};
          button,
          a {
            @apply mt-4 #{!important};
          }
        }
      }
    }
    app-building-info {
      & > div {
        @apply block w-full overflow-hidden #{!important};
        & > * {
          @apply overflow-hidden float-left w-1/2 p-0 pt-4 min-w-fit #{!important};
        }
        & > *:first-child {
          @apply w-full border-b pb-4  #{!important};
          li {
            @apply max-w-[75%] #{!important};
          }
          > * {
            @apply text-ellipsis;
          }
        }
        & > *:last-child {
          @apply w-full justify-center border-t  pt-4 mt-4 #{!important};
        }
      }
    }
    app-device-details {
      .test-header {
        @apply block #{!important};
      }
      section {
        @apply block #{!important};
        & > div {
          @apply m-0 #{!important};
        }
        app-device-info-block mat-card {
          @apply block w-full relative mb-6 #{!important};
        }
        & > div {
          @apply block w-full #{!important};
        }
      }
    }
  }
}
