#av-services-app.is-mobile {
  &.landscape {
    @apply bg-black;
  }

  .card-title {
    @apply text-sm  mb-1.5 mt-2.5  block;
  }

  .toggle-table-filter-btn {
    @apply btn-medium w-10 px-1;

    &.has-selected-filters {
      @apply btn-green;
      i::before {
        @apply text-white;
      }
    }

    &:not(.has-selected-filters) {
      @apply border border-ui-gray-light bg-white rounded-md text-ui-gray-100 flex items-center justify-center;
    }
  }

  button.header-btn {
    width: 40px;
    height: 40px;
    border: 1px solid;
    border-radius: 10px;
    @apply ml-2 text-2xl pt-1 text-white;
    &:first-child {
      @apply ml-0;
    }
  }

  // App main input
  app-main-input-form {
    .input-main-label {
      @apply mt-0 mb-1;
    }

    form {
      @apply w-full;
    }

    .mat-mdc-form-field-subscript-wrapper {
      @apply max-h-3.5;
    }

    > div {
      @apply block;
      label {
        @apply w-full block;
      }
    }
  }

  .input-main-label-mobile {
    @apply text-sm font-semibold text-ui-gray-100 w-full mb-2 leading-cart-title;
  }

  // Slide toggle
  mat-slide-toggle {
    @apply w-full;
    > div {
      @apply w-full flex pb-6;
      label {
        @apply flex-grow flex;
        span {
          @apply flex-grow;
        }
      }
    }
  }

  .mat-mdc-checkbox .mdc-label {
    @apply text-base;
  }

  .mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-label {
    @apply font-bold;
  }

  mat-paginator {
    .mat-mdc-paginator-container {
      @apply flex justify-between px-0 mt-6;
    }

    .mat-mdc-paginator-page-size {
      @apply flex flex-col;
    }

    .mat-mdc-paginator-range-actions {
      @apply block;
    }

    .mat-mdc-paginator-range-label,
    .mat-mdc-paginator-page-size-label {
      @apply mb-2 mx-0;
    }

    .mat-mdc-form-field {
      @apply mx-0;
    }

    .mat-mdc-paginator-range-label {
      @apply text-right mr-4;
    }
  }

  .app-dialog {
    mat-dialog-container {
      .mat-mdc-dialog-surface {
        @apply rounded-lg;
      }

      .dialog-title {
        @apply border-b-0 text-xl p-6;

        button {
          @apply text-ui-gray-100;
        }
      }

      .dialog-content {
        @apply py-4 px-6;
      }

      .dialog-actions {
        @apply pb-4 pt-0 px-6;
      }
    }
  }

  .change-password-popup {
    .dialog-content > div {
      @apply flex-col  w-full #{!important};
    }
  }

  .full-width-checkbox {
    &.mat-mdc-checkbox {
      @apply w-full;
    }

    .mdc-form-field {
      @apply flex;
    }

    .mdc-label {
      @apply w-full;
    }
  }

  .building-select-options {
    .mdc-label {
      @apply flex items-center;
      &::before {
        content: '';
        @apply w-3 h-3 rounded-full mr-2 inline-flex;
      }
    }

    .mat-mdc-checkbox[ng-reflect-value='good'] .mdc-label::before {
      @apply bg-ui-green-light;
    }

    .mat-mdc-checkbox[ng-reflect-value='inProgress'] .mdc-label::before {
      @apply bg-ui-yellow-100;
    }

    .mat-mdc-checkbox[ng-reflect-value='new'] .mdc-label::before {
      @apply bg-ui-red-50;
    }

    .mat-mdc-checkbox[ng-reflect-value='paused'] .mdc-label::before {
      @apply bg-ui-gray-100;
    }
  }

  .map-container {
    div[role='dialog'] {
      max-width: 300px !important;
    }
  }

  mat-card.card {
    @apply px-3 py-4;
  }

  mat-card.divider-card {
    @apply flex-row flex;
    > div {
      &:first-child,
      &:last-child {
        @apply flex-grow w-full text-center px-2;
        & > div:first-child {
          @apply pb-3 text-sm;
        }

        & > div:last-child {
          @apply text-2xl;
          app-incident-status-count {
            @apply mr-1;
          }
        }
      }

      &.divider {
        width: 1px;
        display: flex;
        @apply flex flex-col;
        & > div {
          width: 1px;
          @apply h-1/5;
        }

        & > div.line-block {
          width: 1px;
          height: 70%;
          @apply bg-ui-gray-450 h-3/5;
        }
      }
    }
  }

  // PHone styles
  @media (max-width: 599px) {
    .cdk-overlay-pane.mat-mdc-dialog-panel {
      max-width: 90vw;
    }
    .change-password-popup {
      .dialog-content > div {
        span {
          @apply min-w-4;
        }
      }
    }
  }

  // Fix for filter and full screen floor plan landscape modes
  &.landscape.primary {
    @media (max-width: 960px) {
      .cdk-dialog-container {
        @apply pl-4;
      }
    }

    .floor-plan-full-screen {
      @apply pl-8;
    }

    .exit-fullscreen-btn {
      @apply left-10 #{!important};
    }
  }

  &.landscape.non-primary {
    @media (max-width: 960px) {
      .cdk-dialog-container {
        @apply pr-4;
      }
    }
  }

  mat-button-toggle-group.mobile-main-toggle-group {
    @apply rounded-md border-ui-gray-125;

    .mat-button-toggle-checked {
      button.mat-button-toggle-button {
        @apply border-ui-gray-125 bg-ui-gray-125 text-white hover:bg-ui-gray-125;
      }

      .mat-button-toggle-focus-overlay {
        @apply hidden;
      }

      .mat-button-toggle-label-content {
        @apply text-white;
      }
    }

    mat-button-toggle.mat-button-toggle {
      @apply border-x-0;
      min-width: 100px;
    }

    .mat-button-toggle-label-content {
      @apply leading-[38px] text-sm font-semibold text-ui-dark flex items-center justify-center;
    }

    .mat-pseudo-checkbox {
      @apply hidden;
    }
  }

  .incidents-amount-indicator {
    @apply font-semibold;
  }

  .search-input {
    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
      @apply min-h-[38px];
    }
  }

  .btn-yellow {
    &:hover {
      background-image: linear-gradient(130.3deg, #ffb55e 0%, #ff7464 123.7%);
    }

    &:active {
      background-image: none;
    }
  }

  mat-card.card {
    ul.main-details-list {
      li {
        @apply text-base;

        &:not(:last-child) {
          @apply mb-1;
        }

        span {
          @apply text-ui-gray-100;
        }
      }
    }

    ul.incident-details-list {
      li {
        @apply text-sm font-semibold text-ui-dark;

        &:not(:last-child) {
          @apply mb-3;
        }

        span {
          @apply text-ui-gray-100;
        }
      }
    }
  }

  highcharts-chart.is-mobile {
    .highcharts-reset-zoom {
      transform: translateX(calc(100% - 95px));
    }
  }

  /*Landscape styles*/
  &.landscape {
    app-copyright-footer {
      & > div {
        @apply pt-4 #{!important};
      }
    }

    app-error-404 > div {
      & > div {
        @apply text-[100px] #{!important};
      }

      & > div,
      h1 {
        @apply mt-4 #{!important};
      }
    }

    app-sign-in,
    app-forgot-password {
      h1 {
        @apply my-2 #{!important};
      }

      app-main-logo {
        img {
          @apply max-w-56 #{!important};
        }

        & > div > div {
          @apply pt-4 #{!important};
        }
      }

      mat-card {
        @apply pt-4 #{!important};
      }
    }
  }

  //  Action sheet styles
  .mobile-action-sheet {
    @apply overflow-hidden pb-3;

    .mobile-action-sheet-search-bar {
      @apply bg-white py-2 px-4;
    }

    .mobile-action-sheet-content {
      @apply pt-4  max-h-[55vh] overflow-y-auto px-4 pb-6;
    }
  }

  // table fixes - remove zoom on mobile and normalize the virtual scroll initialization
  app-table-zoom {
    @apply hidden;
  }

  #av-services-app .fixed-scroll {
    @apply min-h-[480px];
  }

  //Device charts
  mat-option {
    .edit-friendly-name {
      @apply flex #{!important};
    }
  }
}
