@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?3lkwx2');
  src:  url('fonts/icomoon.eot?3lkwx2#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?3lkwx2') format('truetype'),
    url('fonts/icomoon.woff?3lkwx2') format('woff'),
    url('fonts/icomoon.svg?3lkwx2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic_power:before {
  content: "\e94a";
}
.icon-ic_temp:before {
  content: "\e94b";
}
.icon-ic_more:before {
  content: "\e949";
}
.icon-ic_rezise:before {
  content: "\e947";
}
.icon-ic_drag-n-drop:before {
  content: "\e948";
}
.icon-ic_status:before {
  content: "\e942";
}
.icon-ic_location:before {
  content: "\e943";
}
.icon-ic_building:before {
  content: "\e944";
}
.icon-ic_reload:before {
  content: "\e945";
}
.icon-ic_widget:before {
  content: "\e946";
}
.icon-ic_column:before {
  content: "\e941";
}
.icon-ic_send:before {
  content: "\e940";
}
.icon-ic_zoom1:before {
  content: "\e93f";
}
.icon-filter_Menu:before {
  content: "\e915";
  color: #737788;
}
.icon-CheckCircleRounded:before {
  content: "\e914";
}
.icon-resend-email:before {
  content: "\e93e";
}
.icon-add_16:before {
  content: "\e900";
}
.icon-close_16:before {
  content: "\e901";
}
.icon-Unassign:before {
  content: "\e902";
}
.icon-link:before {
  content: "\e903";
}
.icon-move:before {
  content: "\e904";
}
.icon-unload-up:before {
  content: "\e905";
}
.icon-eye-open:before {
  content: "\e906";
}
.icon-eye-close:before {
  content: "\e907";
}
.icon-play-16:before {
  content: "\e908";
}
.icon-pause-16:before {
  content: "\e909";
}
.icon-arrow16-up:before {
  content: "\e90a";
}
.icon-arrow16-down:before {
  content: "\e90b";
}
.icon-arrow16-right:before {
  content: "\e90c";
}
.icon-arrow16-left:before {
  content: "\e90d";
}
.icon-edit:before {
  content: "\e90e";
}
.icon-Assign-a-Room:before {
  content: "\e90f";
}
.icon-upload:before {
  content: "\e910";
}
.icon-add-to-archive:before {
  content: "\e911";
}
.icon-restore:before {
  content: "\e912";
}
.icon-offline:before {
  content: "\e913";
}
.icon-warning:before {
  content: "\e916";
}
.icon-active:before {
  content: "\e917";
}
.icon-Deactivate:before {
  content: "\e918";
}
.icon-star:before {
  content: "\e919";
}
.icon-star-stroke:before {
  content: "\e91a";
}
.icon-upload-map:before {
  content: "\e91b";
}
.icon-play-24:before {
  content: "\e91c";
}
.icon-pause-24:before {
  content: "\e91d";
}
.icon-remove:before {
  content: "\e91e";
}
.icon-attach:before {
  content: "\e91f";
}
.icon-calendar:before {
  content: "\e920";
}
.icon-view-floor-plan:before {
  content: "\e921";
}
.icon-close:before {
  content: "\e922";
}
.icon-add:before {
  content: "\e923";
}
.icon-search:before {
  content: "\e924";
}
.icon-check:before {
  content: "\e925";
}
.icon-circle-close:before {
  content: "\e926";
}
.icon-log-out:before {
  content: "\e927";
}
.icon-arrow-up:before {
  content: "\e928";
}
.icon-arrow_down:before {
  content: "\e929";
}
.icon-arrow-right:before {
  content: "\e92a";
}
.icon-arrow-left:before {
  content: "\e92b";
}
.icon-copyright:before {
  content: "\e92c";
}
.icon-username:before {
  content: "\e92d";
}
.icon-Password:before {
  content: "\e92e";
}
.icon-devices-stroke:before {
  content: "\e92f";
}
.icon-devices:before {
  content: "\e930";
}
.icon-my-profile-stroke:before {
  content: "\e931";
}
.icon-my-profile:before {
  content: "\e932";
}
.icon-reporting-stroke:before {
  content: "\e933";
}
.icon-reporting:before {
  content: "\e934";
}
.icon-settings-stroke:before {
  content: "\e935";
}
.icon-settings:before {
  content: "\e936";
}
.icon-users-management-stroke:before {
  content: "\e937";
}
.icon-users-management:before {
  content: "\e938";
}
.icon-incidents-stroke:before {
  content: "\e939";
}
.icon-incidents:before {
  content: "\e93a";
}
.icon-dashboard-stroke:before {
  content: "\e93b";
}
.icon-dashboard:before {
  content: "\e93c";
}
.icon-alert:before {
  content: "\e93d";
}
