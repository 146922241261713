#av-services-app {
  $checkbox-width: 40px;
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control[data-indeterminate='true']:enabled
    ~ .mdc-checkbox__background {
    border-color: var(--green-light, var(--green-light));
    background-color: var(--green-light, var(--green-light));
    color: #fff;
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    color: #fff;
  }

  .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
    background: var(--green-light, var(--green-light));
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__mixedmark {
    border-color: #fff;
  }

  .mat-mdc-checkbox .mdc-label {
    color: var(--dark);
    max-width: calc(100% - var(--checkbox-width));
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .mat-mdc-checkbox .mat-internal-form-field {
    max-width: 100%;
  }
}
