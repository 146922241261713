mat-stepper.app-stepper {
  .mat-horizontal-content-container {
    @apply p-0;
  }

  .mat-horizontal-stepper-header-container {
    @apply border-b-2 pb-1;
    .mat-horizontal-stepper-header {
      @apply h-[52px];
    }
  }

  .mat-stepper-horizontal-line {
    @apply hidden;
  }

  mat-step-header.mat-step-header {
    &:first-child {
      @apply ml-0 pl-5;

      &:after {
        @apply hidden;
      }

      &::before {
        @apply bg-transparent;
      }
    }

    &::before {
      content: '';
      clip-path: polygon(0px 0px, 20px 26px, 0px 52px);
      @apply absolute top-0 -right-5 bg-ui-gray-50 w-5 h-full;
    }

    &::after {
      content: '';
      clip-path: polygon(0px 0px, 20px 0px, 20px 52px, 0px 52px, 20px 26px);
      @apply absolute top-0 -left-5 bg-ui-gray-50 w-5 h-full;
    }

    @apply relative mr-8 bg-ui-gray-50 overflow-visible px-1.5; //   pl-[30px]

    .mat-step-icon {
      @apply w-[32px] h-[32px] mr-3 font-poppins font-semibold;
    }

    p {
      @apply my-0;
    }

    &:hover:not([aria-disabled]):not([ng-reflect-state='done']):not([aria-selected='true']) {
      @apply cursor-pointer;
    }

    &[aria-selected='true'] {
      @apply bg-white cursor-default;

      .mat-step-icon {
        @apply bg-ui-green-light text-white;
      }

      &::before,
      &::after {
        @apply bg-white;
      }
    }
    &[ng-reflect-state='edit'],
    &[ng-reflect-state='done'] {
      @apply cursor-default;
      background: rgba(56, 192, 161, 0.2);

      &::before,
      &::after {
        background: rgba(56, 192, 161, 0.2);
      }
      .mat-step-icon {
        @apply bg-ui-green-light text-white;
      }
    }

    .mat-step-icon {
      @apply bg-transparent border-ui-green-light border text-ui-green-light;
    }

    .mat-step-label {
      @apply text-sm;
    }
  }
}
