.btn-base {
  @apply inline-flex items-center justify-center font-nunito rounded-md transition-colors;

  .mat-icon {
    @apply inline-flex items-center justify-center;
  }
}

.disabled-base {
  @apply bg-ui-gray-light text-ui-gray-100 pointer-events-none cursor-not-allowed bg-none #{!important};

  svg {
    @apply stroke-ui-gray-100 #{!important};
  }
}

.btn-disabled {
  &:disabled {
    @apply disabled-base;
  }
}

.btn-hover-shadow {
  &:hover {
    box-shadow: 0 2px 5px 0 rgba(20, 34, 54, 0.2);
  }
}

.btn-small {
  @apply h-9 text-sm font-normal px-2.5;
}

.btn-medium {
  @apply h-10 text-base font-semibold px-4;
}

.btn-large {
  @apply h-11 text-base font-bold px-9;
}

.btn-green,
.btn-green[type='submit'] {
  @apply btn-base btn-disabled btn-hover-shadow text-white bg-ui-green-light hover:bg-ui-green-350 active:bg-ui-green-light #{!important};
}

.btn-green-stroked {
  @apply btn-base border border-ui-green-light bg-white text-ui-green-light hover:bg-ui-green-250 active:bg-ui-green-50;
}

.btn-blue {
  @apply btn-base btn-disabled btn-hover-shadow text-white bg-ui-blue-100 hover:bg-ui-blue-150 active:bg-ui-blue-100;
}

.btn-red {
  @apply btn-base btn-disabled btn-hover-shadow text-white bg-ui-red-50 hover:bg-ui-red-150 active:bg-ui-red-50;
}

.btn-gray {
  @apply btn-base btn-disabled btn-hover-shadow text-white bg-ui-gray-100 hover:bg-ui-gray-250 active:bg-ui-gray-100;
}

.btn-gray-transparent {
  @apply btn-base btn-hover-shadow text-white;
  background: rgba(29, 30, 37, 0.6);
}

.btn-black {
  @apply btn-base btn-disabled btn-hover-shadow text-white bg-ui-black-100 hover:bg-ui-dark active:bg-ui-black-100;
}

.btn-white-stroked {
  @apply btn-base text-black border border-ui-gray-light bg-white;

  &:hover {
    background-color: rgba(229, 229, 229, 0.2);
  }

  &:active {
    background-color: rgba(229, 229, 229, 0.4);
  }
}

.btn-white-outlined {
  @apply btn-base text-ui-gray-100 border border-ui-gray-light bg-white;

  &:disabled {
    @apply bg-white text-ui-gray-750 pointer-events-none cursor-not-allowed #{!important};
  }

  &:hover {
    @apply bg-ui-gray-850;
  }

  &:active {
    @apply bg-ui-gray-light;
  }
}

.btn-yellow {
  @apply btn-base btn-disabled btn-hover-shadow text-white bg-ui-yellow-300 hover:bg-none;
  background-image: linear-gradient(130.3deg, #ffb55e 0%, #ff7464 123.7%);
  transition: background 150ms cubic-bezier(0.4, 0, 0.2, 1);
  &:active {
    background-image: linear-gradient(130.3deg, #ffb55e 0%, #ff7464 123.7%);
  }
}

.btn-transparent {
  @apply btn-base bg-transparent text-black;

  &:hover {
    background-color: rgba(229, 229, 229, 0.2);
  }

  &:active {
    background-color: rgba(229, 229, 229, 0.4);
  }
}

.clear-btn {
  background: transparent;
  @apply px-3 py-3 rounded-md text-black min-w-[100px] font-semibold h-[44px];
}

.load-btn {
  input[type='file'] {
    @apply opacity-0 absolute w-full h-full left-0 top-0 cursor-pointer;
  }

  input[type='file']::file-selector-button {
    @apply absolute left-0 top-0 w-full h-full cursor-pointer;
  }
}

.generate-report-btn {
  &:disabled {
    @apply text-white #{!important};

    svg {
      @apply stroke-white #{!important};
    }
  }
}
