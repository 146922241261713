#av-services-app {
  mat-form-field.icon-input {
    @apply px-2 w-full;
    .mat-mdc-form-field-subscript-wrapper {
      @apply hidden;
    }
    .mat-mdc-form-field-focus-overlay {
      @apply bg-white;
    }
    &.mat-focused {
      .mat-mdc-text-field-wrapper {
        @apply border-ui-green-light;
      }
    }
    .mdc-line-ripple {
      @apply hidden;
    }
    .mat-mdc-text-field-wrapper {
      @apply rounded-lg h-[36px] bg-white border;
    }

    .mat-mdc-form-field-infix,
    mat-icon {
      @apply py-1 min-h-[36px];
    }
    mat-icon {
      @apply text-ui-gray-light px-1 min-h-fit;
    }

    input {
      @apply font-poppins;
    }
  }
}
