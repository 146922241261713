#av-services-app {
  .column-select {
    @apply w-[36px];

    &.mat-focused {
      .mdc-text-field {
        @apply border-ui-green-light;
      }

      &.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mat-mdc-floating-label {
        @apply text-white;
      }

      .mat-mdc-form-field-focus-overlay {
        @apply opacity-100 bg-ui-green-light;
      }
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      @apply bg-transparent;
    }

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      @apply py-0 cursor-pointer min-h-[34px];
    }

    .mat-mdc-select-arrow-wrapper {
      @apply hidden;
    }

    .mdc-text-field {
      @apply border rounded-md border-ui-gray-light p-0;
    }

    .mat-mdc-select-value,
    .mat-mdc-form-field-subscript-wrapper,
    .mdc-line-ripple {
      @apply hidden;
    }

    &.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mat-mdc-floating-label {
      @apply max-w-none text-sm text-ui-gray-100 top-[5px] px-0 cursor-pointer transform-none left-[5px];
    }

    .mdc-text-field--filled .mdc-floating-label--float-above {
      @apply transform-none;
    }
  }

  .mat-mdc-form-field.stroked-select-multiple {
    @apply mb-5;

    &.mat-focused {
      .mdc-text-field {
        @apply border-ui-green-light;
      }

      .mat-mdc-form-field-focus-overlay {
        @apply opacity-100 bg-transparent;
      }

      .arrow-up {
        @apply inline-block;
      }

      .arrow-down {
        @apply hidden;
      }
    }

    &.has-selected-value:not(.mat-focused) {
      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        @apply border-ui-green-50 bg-ui-green-50;
      }
    }

    .mat-mdc-select {
      @apply w-0 overflow-hidden;
    }

    .mat-mdc-select-value,
    .mat-mdc-form-field-subscript-wrapper,
    .mdc-line-ripple {
      @apply hidden;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      @apply bg-transparent;
    }

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      @apply cursor-pointer min-h-[34px] py-0 flex items-center w-auto;
    }

    .mdc-text-field {
      @apply border rounded-md border-ui-gray-light p-0;
    }

    &.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mat-mdc-floating-label {
      @apply font-nunito text-sm text-ui-dark static transform-none left-0 top-0 max-w-none;
    }

    .mat-mdc-form-field-flex {
      @apply flex items-center justify-between px-2.5;
    }

    .mat-mdc-form-field-icon-suffix {
      .mat-icon {
        @apply p-0;
      }
    }

    .arrow-up {
      @apply hidden;
    }
  }

  .mat-mdc-form-field.stroked-select-single {
    &.mat-focused {
      .mdc-text-field {
        @apply border-ui-green-light;
      }

      .mat-mdc-form-field-focus-overlay {
        @apply opacity-100 bg-transparent;
      }

      .arrow-up {
        @apply inline-block;
      }

      .arrow-down {
        @apply hidden;
      }
    }

    .mdc-line-ripple {
      @apply hidden;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      @apply bg-transparent;
    }

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      @apply cursor-pointer min-h-[34px] py-0 flex items-center w-auto;
    }

    .mdc-text-field {
      @apply border rounded-md border-ui-gray-light p-0;
    }

    &.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mat-mdc-floating-label {
      @apply font-nunito text-sm text-ui-dark static transform-none left-0 top-0 max-w-none overflow-clip;
    }

    .mat-mdc-form-field-flex {
      @apply flex items-center justify-between px-2.5;
    }

    .mat-mdc-form-field-icon-suffix {
      .mat-icon {
        @apply p-0;
      }
    }

    .arrow-up {
      @apply hidden;
    }

    .mat-mdc-select {
      @apply w-auto;

      .mat-mdc-select-value-text {
        @apply text-sm;
        &::before {
          content: ': ';
        }
      }
    }

    .mat-mdc-select-arrow-wrapper {
      @apply hidden;
    }

    .mat-mdc-form-field-subscript-wrapper {
      @apply hidden;
    }

    &:not(.mat-form-field-hide-placeholder):not(.mat-focused) {
      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        @apply border-ui-green-50 bg-ui-green-50;
      }
    }
  }

  .cdk-overlay-pane .mat-mdc-select-panel.column-select-overlay {
    @apply w-[230px];
    margin-left: -160px;
  }

  .map-site-filter {
    @apply absolute right-2 z-10 top-2;

    .mat-mdc-form-field-flex {
      @apply bg-white;
    }
  }

  .cdk-overlay-pane .mat-mdc-select-panel.multi-select-overlay {
    @apply mt-1;

    .mat-pseudo-checkbox-full {
      @apply text-ui-green-light;
    }

    .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
      @apply bg-ui-green-light;
    }

    .mat-mdc-option {
      @apply text-ui-dark font-normal text-sm min-h-[40px];

      .mdc-list-item__primary-text {
        @apply text-ui-dark;
      }

      &:hover {
        @apply bg-ui-green-150;
      }
    }

    .options-action-wrapper {
      @apply flex items-center justify-between px-4 mb-2;
    }

    .options-action-button {
      @apply text-ui-dark font-normal text-xs underline;
    }
  }

  .device-type-overlay {
    @apply min-w-[180px];
  }

  .assigned-user-overlay {
    @apply min-w-[190px];
  }

  .status-overlay {
    @apply min-w-[190px];
  }

  .room-overlay,
  .multiselect-alert-overlay,
  .multiselect-building-overlay {
    @apply min-w-[190px];
  }

  .multiselect-map-filter-overlay {
    @apply min-w-[315px];
    margin-left: -224px;

    .mdc-list-item__primary-text {
      @apply flex items-center;
      &::before {
        content: '';
        @apply w-3 h-3 rounded-full mr-2 inline-flex;
      }
    }

    .mat-mdc-option[data-value='good'] .mdc-list-item__primary-text::before {
      background-color: #0e7c67;
    }

    .mat-mdc-option[data-value='inProgress'] .mdc-list-item__primary-text::before {
      @apply bg-ui-yellow-100;
    }

    .mat-mdc-option[data-value='new'] .mdc-list-item__primary-text::before {
      @apply bg-ui-red-50;
    }

    .mat-mdc-option[data-value='paused'] .mdc-list-item__primary-text::before {
      background-color: #8c90a1;
    }
  }

  .device-room-filter-overlay {
    @apply min-w-[200px];
  }

  .cdk-overlay-pane .mat-mdc-select-panel.single-select-overlay {
    .mat-mdc-option {
      &.mdc-option-active,
      &.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
        @apply bg-transparent;
      }

      @apply text-ui-dark font-normal text-sm min-h-[40px];

      .mdc-list-item__primary-text {
        @apply text-ui-dark w-full;
      }

      &.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
        @apply text-ui-green-light;
      }

      &:hover {
        @apply bg-ui-green-150;

        .icon-button {
          @apply visible;
        }
      }

      .mat-pseudo-checkbox {
        @apply hidden;
      }
    }

    .full-width-option {
      .mdc-list-item__primary-text {
        @apply w-full;
      }
    }

    .icon-button {
      @apply invisible;
    }
  }

  .cdk-overlay-pane .mat-mdc-select-panel.space-select-overlay {
    width: 200px;
    position: absolute;
    left: 50%;
    margin-left: -100px;
  }

  .incident-count-indicator {
    @apply w-5 h-5 inline-flex justify-center items-center bg-ui-red-100 font-poppins text-sm text-white font-normal rounded-full;
  }

  .desktop-toggle-group {
    &.mat-button-toggle-group {
      @apply rounded-none border-none;
    }

    .mat-button-toggle-label-content {
      @apply flex justify-center items-center leading-[64px];
    }

    .item-status-title {
      @apply relative text-ui-dark text-sm font-poppins px-1;
    }

    .mat-button-toggle {
      &:last-child {
        @apply border-l-0;
      }

      .mat-pseudo-checkbox {
        @apply hidden;
      }

      &.mat-button-toggle-checked {
        @apply bg-transparent;

        .mat-button-toggle-label-content {
          @apply font-semibold;
        }

        .item-status-title {
          &::after {
            @apply absolute left-0 w-full h-[6px] bg-ui-green-light rounded-[100px] bottom-[-3px];
            content: '';
          }
        }
      }
    }
  }

  .mobile-toggle-group {
    &.mat-button-toggle-group {
      @apply rounded-none border-none;
    }

    .mat-button-toggle-label-content {
      @apply flex justify-center items-center leading-[54px];
    }

    .mat-pseudo-checkbox {
      @apply hidden;
    }

    .mat-button-toggle {
      @apply border-l-0 border-r-0 bg-transparent rounded-none;
      &:not(:first-child) {
        @apply ml-2;
      }

      .mat-button-toggle-focus-overlay {
        @apply hidden;
      }

      .mat-button-toggle-label-content {
        @apply py-0 px-3 font-poppins text-base text-black;
      }

      &.mat-button-toggle-checked {
        .mat-button-toggle-label-content {
          @apply font-semibold;

          &::after {
            @apply absolute left-0 w-full h-[3px] bg-ui-green-light bottom-0;
            content: '';
          }
        }
      }
    }
  }

  .search-input,
  .input-with-clear-btn {
    .mdc-line-ripple,
    .mat-mdc-form-field-focus-overlay,
    .mat-mdc-form-field-subscript-wrapper {
      @apply hidden;
    }

    .mdc-text-field__input {
      @apply font-nunito text-sm;
    }

    .mdc-text-field {
      @apply rounded-md border border-ui-gray-light;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      @apply bg-transparent;
    }

    &.mat-focused {
      .mdc-text-field {
        @apply border-ui-green-light;
      }

      .mat-mdc-form-field-icon-prefix {
        @apply hidden;
      }
    }

    .mat-mdc-form-field-flex {
      @apply px-2.5;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
      @apply min-h-[34px] py-0 flex;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      @apply w-6 h-6 p-0;
    }

    &.no-border {
      .mdc-text-field {
        @apply border-none;
      }
    }
  }

  .filters-toggle {
    @apply border border-ui-gray-light rounded-md font-nunito text-sm text-ui-dark flex items-center px-2.5 transition-colors;
    letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);

    .button-toggle-title {
      @apply relative inline-block pr-2.5;
    }

    &.filter-indicator {
      .button-toggle-title::after {
        @apply absolute w-1.5 h-1.5 rounded-full bg-ui-red-100;
        content: '';
      }
    }

    &.is-open {
      @apply border-ui-green-light bg-ui-green-light text-white;
    }
  }

  .mat-mdc-form-field-type-mat-date-range-input.date-range-picker {
    &.no-validation-message {
      .mat-mdc-form-field-subscript-wrapper {
        @apply hidden;
      }
    }

    &.mat-focused {
      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        @apply border-ui-green-light;
      }
    }

    &.mat-form-field-invalid {
      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        @apply border-ui-red-100;
      }
    }

    &.no-validation-errors {
      .mat-mdc-form-field-subscript-wrapper {
        @apply hidden;
      }
    }

    .mdc-line-ripple {
      @apply hidden;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      @apply bg-transparent border rounded-md border-ui-gray-light;
    }

    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
      @apply font-nunito text-sm text-ui-dark;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      @apply h-8 w-8 p-1;

      &.cdk-focused .mat-mdc-button-persistent-ripple::before {
        @apply opacity-0;
      }
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
      @apply h-8 w-8;
    }

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      @apply py-0;
    }

    .mat-mdc-form-field-infix {
      @apply min-h-[34px] w-[180px];
    }

    &.middle-height {
      .mat-mdc-form-field-infix {
        @apply min-h-[38px];
      }
    }

    .mat-mdc-form-field-focus-overlay {
      @apply bg-transparent;
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      @apply top-[18px];
    }

    .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
      @apply hidden;
    }

    .mdc-text-field--filled .mdc-floating-label--float-above {
      @apply hidden;
    }

    .mat-date-range-input {
      @apply mt-1.5 font-nunito text-sm text-ui-dark;
    }

    .mat-mdc-text-field-wrapper {
      @apply pl-2.5 cursor-pointer;
    }

    .mat-date-range-input-wrapper input {
      @apply cursor-pointer;
    }

    &:not(.mat-form-field-hide-placeholder):not(.mat-focused):not(.mat-form-field-invalid) {
      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        @apply border-ui-green-50 bg-ui-green-50;
      }
    }
  }

  .device-action-button {
    @apply flex items-center px-2 rounded-md font-nunito text-sm text-white disabled:cursor-not-allowed;

    &.device-resume-button {
      @apply bg-ui-green-light;

      &:disabled {
        @apply text-ui-green-light;
        background: rgba(56, 192, 161, 0.2);

        svg {
          @apply fill-ui-green-light;
        }
      }
    }

    &.device-pause-button {
      @apply bg-ui-red-50;

      &:disabled {
        @apply text-ui-red-50;
        background: rgba(254, 90, 90, 0.2);
      }
    }
  }

  mat-button-toggle-group.main-toggle-group {
    background: rgba(229, 229, 229, 0.6);
    @apply border-none px-1 py-[3px] rounded-md;

    .mat-button-toggle-checked {
      button.mat-button-toggle-button {
        @apply bg-white text-ui-dark;
      }

      .mat-button-toggle-focus-overlay {
        @apply hidden;
      }
    }

    button.mat-button-toggle-button {
      @apply bg-transparent text-ui-gray-100;
    }

    mat-button-toggle.mat-button-toggle {
      @apply rounded-md border-none bg-transparent overflow-hidden;

      &:not(:last-child) {
        @apply mr-1;
      }
    }

    .mat-button-toggle-label-content {
      @apply leading-[34px] text-sm px-6 font-semibold;
    }

    .mat-pseudo-checkbox {
      @apply hidden;
    }

    .mat-button-toggle-focus-overlay {
      @apply rounded-none;
    }
  }
}
